import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Nav from "./components/Nav";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Work from "./pages/Work";
import CommingSoon from "./pages/CommingSoon";
import NotFound from "./pages/NotFound";
import Footer from "./components/Footer";
import Portfolio from "./pages/projects/Portfolio";
import BackButton from "./components/Buttons/BackButton";

const RDPortfolio = (props) => {
  useEffect(() => {
    if (props.to === "pp") {
      window.location.href = "https://dinkar.framer.website";
    } else if (props.to ==="rsmUX") {
      window.location.href = "https://docs.google.com/document/d/1dHXcbVnvGnWywE0TPyPHNmNJ3aD1kc1eYhQTj00tbT0/edit?usp=sharing";
    }else if (props.to ==="rsmUX") {
      window.location.href = "dinkar.framer.website";
    }
  }, []);

  return null;
};

const App = () => {
  return (
    <Router>
      <div className={`flex transition-all font-poppins justify-center flex-col items-center text-white `}>
        <Routes>
          <Route path="/" element={<RDPortfolio to="pp"/>} />
          <Route path="/uxresume" element={<RDPortfolio to="rsmUX"/>} />
          <Route path="/devresume" element={<RDPortfolio to="rsmDEV"/>} />
          
        </Routes>
      </div>
    </Router>
  );
};

export default App;
