import React from "react";

import { FaArrowLeft } from "react-icons/fa6";
import scrollToElement from "../ScrollToTop";
import { Link } from "react-router-dom";

 const BackButton = ({to}) =>  {
  return (
    <Link to={to} onClick={() => scrollToElement("page-top", 10)}>
    <div className=" hover:-translate-x-2 sec-btn border-4 hover:border-violet-600 transition-all  absolute md:top-10 top-5 left-5 z-50 backdrop-blur-xl md:left-12 p-2  rounded-full text-4xl">
      <FaArrowLeft />
    </div></Link>
  );
}

export default BackButton;